import { createApp } from 'vue'
import App from '@/App.vue'
import '@/assets/tailwind.css'
import { createPinia } from 'pinia'
import router from '@/helpers/router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueBasicAlert from 'vue-basic-alert'
import { useAuthStore } from '@/stores/AuthStore';
import { useSourceStore } from '@/stores/SourceStore';
import { useProductStore } from '@/stores/ProductStore';
import { useNavStore } from '@/stores/NavStore';
import { useSectionStore } from './stores/SectionStore';
import { useClassStore } from './stores/ClassStore';
import { useSubclassStore } from './stores/SubclassStore';
import { useIndexStore } from './stores/IndexStore';
import vSelect from 'vue-select'
//import https from 'https';

import 'flowbite';
import 'vue-select/dist/vue-select.css';

axios.defaults.baseURL = "http://181.13.244.45:7078/Api/";
//axios.defaults.baseURL = "https://localhost:7077/Api/";
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.indexAppToken;	
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';	
/*axios.defaults.httpsAgent = new https.Agent({  
    rejectUnauthorized: false
  });*/

const app =  createApp(App)

app.use(router)
app.use(VueAxios, axios)
app.use(createPinia())
app.use(VueBasicAlert)
app.component('v-select', vSelect)
app.mount('#app')

// eslint-disable-next-line
const authStore = useAuthStore();
// eslint-disable-next-line
const navStore = useNavStore();
// eslint-disable-next-line
const sourceStore = useSourceStore();
// eslint-disable-next-line
const productStore = useProductStore();
// eslint-disable-next-line
const sectionStore = useSectionStore();
// eslint-disable-next-line
const classStore = useClassStore();
// eslint-disable-next-line
const subclassStore = useSubclassStore();
// eslint-disable-next-line
const indexStore = useIndexStore();


