import {defineStore} from 'pinia'
import axios from "axios"
 
export const useClassStore  = defineStore(
    {   id: "ClassStore",    
        state: ()=>({                       
                data: [] ,
                classes: [],
                sectionId: '',
                classId: '',
                effectiveDate: null,
                effectiveDateString: '',
                effectiveDateModel: '',
                effectiveStatus: '',           
                description: '',
                coefficient: 0,
                subclasses: []
        }), 
        getters:{
            getClasses(state){                
               return state.classes
            }
        },
        actions:{  
            setClassDefaults(){
                this.sectionId ='';
                this.classId = '';
                this.effectiveDate = new Date('1900-01-01T00:00:00');
                this.effectiveDateString = '1900-01-01T00:00:00';
                this.effectiveDateModel = '1900-01-01';
                this.effectiveStatus = 'A';
                this.description = '';
                this.coefficient = 0;
            },                 
            fetchClass( class_obj ){

               
                let row = this.classes.find( obj => obj.sectionId == class_obj.sectionId && obj.classId == class_obj.classId);
                if(row == undefined){
                    let initrow = {
                        sectionId : class_obj.sectionId,                        
                        classId: class_obj.classId,
                        rows : []
                    };
                    this.classes.push(initrow);
                    row = this.classes.find( obj => obj.sectionId == class_obj.sectionId && obj.classId == class_obj.classId);
                }                

                row.rows.push({
                    effectiveDate : new Date(class_obj.effectiveDate),
                    effectiveDateString: class_obj.effectiveDate,
                    effectiveDateModel: class_obj.effectiveDate.slice(0,10),
                    description: class_obj.description,
                    effectiveStatus: class_obj.effectiveStatus,
                    coefficient: class_obj.coefficient
                });       
            },           
            async fetchClasses() {               
                try {                
                  const data = await axios.get('/IndexSetting/Class');                  
                  this.data = data.data.data;
                  this.classes = [];
                  this.data.forEach( e => this.fetchClass(e));
                  this.classes.forEach( e => e.rows.sort( (a,b) => { return b.effectiveDate < a.effectiveDate}  ) );       
                  }
                  catch (error) {
                    console.log(error)
               }
            },
            async addClass(){
                try {
                    let dataToSend = {
                        sectionId: this.sectionId,
                        effectiveDate: this.effectiveDateString,
                        classId: this.classId,
                        effectiveStatus: this.effectiveStatus,
                        description: this.description,
                        coefficient: this.coefficient
                    }               
                    const data = await axios.post('/IndexSetting/Class/Add', dataToSend);                  
                    this.data = data.data.data;
                    return true;
                }
                    catch (error) {
                    console.log(error);
                    return false;
                }
            },            
            async getClass(sectionId, effectiveDate, classId){
                try {                
                    const data = await axios.get('/IndexSetting/Class/'+sectionId+'/'+effectiveDate+'/'+classId);    
                    this.sectionId = data.data.data[0].sectionId;
                    this.effectiveDate = new Date(data.data.data[0].effectiveDate);
                    this.effectiveDateString = data.data.data[0].effectiveDate;
                    this.effectiveDateModel = this.effectiveDateString.slice(0,10);
                    this.classId = data.data.data[0].classId;
                    this.effectiveStatus = data.data.data[0].effectiveStatus;
                    this.description = data.data.data[0].description;
                    this.coefficient = data.data.data[0].coefficient;
                    this.subclasses = data.data.data[0].subclasses;  
                    this.subclasses.forEach((el)=>{
                        el.effectiveDateString = el.effectiveDate;                     
                        el.effectiveDate = new Date(el.effectiveDateString);
                        el.effectiveDateModel = el.effectiveDateString.slice(0,10);
                    });  
                    console.log(this.subclasses);
                    return true;
                }
                    catch (error) {
                    console.log(error);
                    return false;
                }
            },
            async editClass(){
                try {         
                    let dataToSend = {
                        sectionId: this.sectionId,
                        effectiveDate: this.effectiveDateString,
                        classId: this.classId,
                        effectiveStatus: this.effectiveStatus,
                        description: this.description,
                        coefficient: this.coefficient
                    }       
                    const data = await axios.post('/IndexSetting/Class/Edit', dataToSend);                  
                    this.data = data.data.data;
                    return true;
                }
                    catch (error) {
                    console.log(error);
                    return false;
                }
            },
            async deleteClass(id){
                try {
                    await axios.delete('/IndexSetting/Class/' + id);                                          
                    return true;
                }
                    catch (error) {
                    console.log(error);
                    return false;
                }
            },   
            fetchClassEffdt( class_obj ){
                this.classes.push({
                    sectionId : class_obj.sectionId,
                    classId: class_obj.classId,
                    effectiveDate : new Date(class_obj.effectiveDate),
                    effectiveDateString: class_obj.effectiveDate,
                    description: class_obj.description,
                    effectiveStatus: class_obj.effectiveStatus,
                    coefficient: class_obj.coefficient
                });
            },      
            async fetchClassEffdts(sectionId, classId) {               
                try {                
                  const data = await axios.get('/IndexSetting/Class/'+sectionId+'/'+classId);     
                  this.data = data.data.data;
                  this.classes = [];
                  this.data.forEach( e => this.fetchClassEffdt(e));
                  this.classes.forEach( e => e.rows.sort( (b,a) => { return b.effectiveDate < a.effectiveDate}  ) );       
                  }
                  catch (error) {
                    console.log(error)
               }
            },                      
        }                      
    }    
)