<template>
    <div class="h-full grid place-items-center">        
        <LoadingSpinnerImg />      
    </div>      
</template>
<script>
import LoadingSpinnerImg from './LoadingSpinnerImg.vue'

export default{
    name:"LoadingSpinner",
    components:{
        LoadingSpinnerImg
    },
}
</script>
