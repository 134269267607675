<template>
    <div class="section-add">       
        <LoadingSpinner  v-if="loading" />                           
        <div  v-if="!loading">
            <h1 class="bg-principal text-white text-left pl-5">Sección</h1>
            <div class="flex flex-col">
                <!--<div class="text-left text-xs">Configuración > Sección > Agregar </div>-->
                <div>
                    <div class="overflow-hidden mt-4">
                        <form action="">
                            <div class="text-left py-2">
                                <label class="text-left" for="name">ID</label>
                                <input class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" type="text" v-model="store.id" />
                            </div>
                            <div class="text-left py-2">
                                <label class="text-left" for="name">Fecha Efectiva</label>
                                <input class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" type="date" v-model="store.effectiveDateModel" />
                            </div>
                            <div class="text-left py-2">
                                <label class="text-left" for="name">Estado</label>
                                <select class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" v-model="store.effectiveStatus">
                                    <option value="A"> Activo </option>
                                    <option value="I"> Inactivo </option>
                                </select>
                            </div>
                            <div class="text-left py-2">
                                <label class="text-left" for="name">Descripción</label>
                                <input class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" type="text" v-model="store.description" />
                            </div>
                            <div class="text-left py-2">
                                <label class="text-left" for="name">Coeficiente</label>
                                <input class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" type="number" step="0.01" v-model="store.coefficient"/>
                            </div>
                            <action-bar :save_action="addSection" :save_label="'Guardar'" :back_label="'Cancelar'"/>                            
                        </form>
                    </div>
                </div>
            </div>            
        </div> 
        <vue-basic-alert 
        :duration="300" 
        :closeIn="10000"
        ref="saveAlert" />        
    </div>
</template>
<script>
import { useSectionStore } from "@/stores/SectionStore"
import LoadingSpinner from '@/components/LoadingSpinner.vue'
// eslint-disable-next-line
import VueBasicAlert from 'vue-basic-alert'
import ActionBar from '@/components/ActionBar.vue'

export default {
    name: 'SectionAddView',
    components:{
        LoadingSpinner,
        ActionBar
    },
    data(){
        return {                     
            loading: true,
            store : useSectionStore(),
        }
    },
    mounted(){               
         this.store = useSectionStore(); 
         this.store.setSectionDefaults();   
         this.loading = false;                      
    },
     methods: {       
        goEdit: function(id, effdt){
            this.$router.push('/section/' + id + '/' + effdt);   
        },
        addSection: function(){                       
            this.store.addSection().then((success)=>{
                if(success){
                    this.$refs.saveAlert.showAlert(
                    'success',
                    'La sección fue creada correctamente.',
                    'Éxito',
                    { 
                        iconSize: 35, 
                        iconType: 'solid', 
                        position: 'top right' } 
                    );
                    
                    this.goEdit(this.store.id, this.store.effectiveDateString);                       
                    
                } else {                    
                    this.$refs.saveAlert.showAlert(
                    'error',
                    'No fue posible crear la sección.',
                    'Alerta',
                    { 
                        iconSize: 35, 
                        iconType: 'solid', 
                        position: 'top right' } 
                    );
                }
            });                    
        }
    }
}

</script>

<style scoped>

</style>