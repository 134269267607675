import {defineStore} from 'pinia'
import axios from "axios"

export const useSourceStore  = defineStore(
    {   id: "SourceStore",    
        state: ()=>({           
                data: [] ,
                source: '',
                sourceDate: null,
                products: [],   
                sourceList: [],
                sourcelist_page: 1,
                sourcelist_total_pages: 0,
                sourcelist_page_size: 10       
        }), 
        getters:{
            getSources(state){
               return state.data
            },
            getSourceList(state){
                return state.sourceList;
            }
        },
        actions:{
            async fetchSources() {               
                try {                
                  const data = await axios.get('/Dashboard');                  
                  this.data = data.data.data;                
                  }
                  catch (error) {
                    console.log(error)
                }
            },
            setSource(source, sourceDate){
                this.source = source;
                this.sourceDate = sourceDate;
            },           
            fetchSourceListItem(obj){
                obj.scrapDateString = obj.scrapDate;              
                obj.scrapDate = new Date(obj.scrapDate);                
            }, 
            async fetchSourceList(){
                try {                
                    const data = await axios.get('/ScrapInstance?PageNumber='+this.sourcelist_page+'&PageSize='+this.sourcelist_page_size);   
                    this.sourcelist_page = data.data.pageNumber;
                    this.sourcelist_page_size = data.data.pageSize;
                    this.sourcelist_total_pages = data.data.totalPages;               
                    this.sourceList = data.data.data;  
                    this.sourceList.forEach( e => this.fetchSourceListItem(e));                  
                }
                    catch (error) {
                      console.log(error)
                }
            }
        }                      
    }    
)