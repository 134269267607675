<template>  
<main class="flex flex-col h-screen font-sans">
  <div class="flex flex-1 overflow-hidden">
    <div class="flex">
      <NavbarAside />
    </div>
    <div class="flex flex-1 flex-col">
      <!--<div class="text-left text-xs w-100 p-2"> <h1>{{ navStore.breadcum }} </h1></div>-->
      <div class="flex flex-1 overflow-y-auto paragraph px-4 justify-center" id="main-content">        
        <router-view> </router-view> 
      </div>
    </div>
  </div> 
  <div class="grid grid-cols-6 h-1">
    <div class="bg-jumbo-primary"></div>
    <div class="bg-fravega-primary"></div>
    <div class="bg-coto-primary"></div>
    <div class="bg-easy-primary"></div>
    <div class="bg-dafiti-primary"></div>
    <div class="bg-carrefour-primary"></div>
    <div class="bg-manual-primary"></div>
  </div>
    
</main>
</template>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
/*
nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}*/

.v-select * ul{
  z-index: 9999 !important;
}
</style>

<script> 
import NavbarAside from '@/components/NavbarAside.vue'
import { useNavStore } from "@/stores/NavStore"

export default {
  name: "App",
   components: {
    NavbarAside    
  },
  data(){
      return {
          navStore: useNavStore()         
      }
  },
}

</script>
