<template>
    <div class="class-effdt-index">        
        <LoadingSpinner  v-if="loading" />                           
        <div  v-if="!loading">
            <h1 class="bg-principal text-white text-left pl-5">Clase</h1>
            <DataTable :headers="headers" :rows="rows" :actions="actions"/>    
            <action-bar :back_label="'Volver'"/>        
        </div>
    </div>
</template>
<script>
import { useClassStore } from "@/stores/ClassStore"
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import DataTable from '@/components/DataTable.vue'
import { useRoute } from 'vue-router'
import ActionBar from '@/components/ActionBar.vue'

export default {
    name: 'ClassEffdtView',
    components:{
        LoadingSpinner,
        DataTable,
        ActionBar
    },
    data(){
        return {
            classes: [],            
            error: false,
            error_msg: "",
            loading: true,
            store : useClassStore(),
            headers:[],
            rows: [],
            actions: [],
            sectionId: '',
            classId: ''
        }
    },
    mounted(){   
        const route = useRoute();    
        this.sectionId = route.params.sectionId;
        this.classId = route.params.classId;

        this.store = useClassStore(); 
        this.headers = ['ID Sección', 'Fecha Efectiva', 'ID Clase', 'Descripción', 'Estado', 'Coeficiente'];

        this.actions['edit'] = this.editRowAction;         

        this.store.fetchClassEffdts( this.sectionId, this.classId  ).then(()=>{
            this.loading = false;
            this.classes = this.store.getClasses;            
            this.classes.forEach( el => this.pushRow(el) );
            console.log(this.classes);
        });                 
    },
     methods: {
        editRowAction: function(editId){
            this.$router.push('/class/' + editId);   
        },               
        pushRow: function(class_obj){
            let element = class_obj;            
            let row = [];                           
            let effectiveDate = element.effectiveDate.getDate() + '/'+ (element.effectiveDate.getMonth()+1)+'/'+element.effectiveDate.getFullYear();
            row['edit_id'] = class_obj.sectionId + '/' + element.effectiveDateString + '/' + class_obj.classId;                 
            row['data'] = [class_obj.sectionId, effectiveDate, class_obj.classId, element.description, element.effectiveStatus , element.coefficient];
            this.rows.push(row);
        }
    }
}
</script>