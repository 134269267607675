<template>
    <div class="login fixed top-0 left-0 right-0 left-0 z-40">
        <div class="bg-white bg-gray-900">
            <div class="flex justify-center h-screen">
                <div class="hidden bg-cover lg:block lg:w-2/3" :style="{ backgroundImage: 'url(' + require('@/assets/login-background.webp') + ')' }">
                    <div class="flex items-center h-full px-20 bg-gray-900 bg-opacity-40">
                        <div>
                            <img class="bg-blend-multiply" src="@/assets/invecq-white-transparent.webp">                                                                
                        </div>
                    </div>
                </div>            
                <div class="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
                    <div class="flex-1">
                        <div class="text-center">
                            <h3 class="text-4xl font-bold text-white mt-16 uppercase">Índice de Precios</h3>                         
                            <p class="mt-3 text-gray-300">Inicia sesión para acceder a tu cuenta</p>
                        </div>
                        <div class="mt-8">
                            <form v-on:submit.prevent="login">
                                <div>
                                <div class="flex justify-between mb-2">
                                        <label for="user" class="text-sm text-gray-200">Usuario o Email</label>                                    
                                    </div>                                
                                    <input type="text" name="user" id="user" placeholder="example@example.com" class="block w-full px-4 py-2 mt-2 placeholder-gray-600 border rounded-md bg-gray-900 text-gray-300 border-gray-700 focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" required v-model="user"/>
                                </div>
                                <div class="mt-6">
                                    <div class="flex justify-between mb-2">
                                        <label for="password" class="text-sm text-gray-200">Contraseña</label>                                    
                                    </div>

                                    <input type="password" name="password" id="password" placeholder="Your Password" class="block w-full px-4 py-2 mt-2 border rounded-md placeholder-gray-600 bg-gray-900 text-gray-300 border-gray-700 focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" required v-model="password"/>
                                </div>
                                <div class="mt-6">
                                    <button
                                        class="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50"
                                        :disabled="loading">
                                        <span v-if="!loading">Acceder</span> <LoadingSpinnerImg v-if="loading"/>  
                                    </button>
                                </div>
                            </form>                        
                            <div class="flex justify-end mt-4 mb-2">
                            <a href="#" class="text-sm text-gray-400 focus:text-blue-500 hover:text-blue-500 hover:underline">Olvidó su contraseña?</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     <vue-basic-alert 
       :duration="300" 
       :closeIn="10000"
       ref="LoginError" />            
</template>

<script>
// eslint-disable-next-line
import VueBasicAlert from 'vue-basic-alert'
import LoadingSpinnerImg from './LoadingSpinnerImg.vue'
import { useAuthStore } from '@/stores/AuthStore';


export default {
    name:'LoginForm',
    components:{
        LoadingSpinnerImg
    },
    data: function(){
    return {
      user: "",
      password: "",
      error: false,
      error_msg: "",
      loading: false,
    }
  },
  created(){
    //Logout
    useAuthStore().logout();   
  },
  methods: {
    login: function(){
        let global = this;
        global.loading = true;
        const auth = useAuthStore(); 
        auth.login(this.user, this.password,function(){  
           global.loading = false;       
           auth.reset();
           global.$router.push('/dashboard'); 
           //global.$router.go('/'); 
        },
        function(){
             global.$refs.LoginError.showAlert(
                 'error',
                 'Usuario y/o contraseña inválidos.',
                 'Acceso denegado',
                { 
                    iconSize: 35, 
                    iconType: 'solid', 
                    position: 'top right' } 
                );
                global.loading = false;
        }, function(){
            global.$refs.LoginError.showAlert(
                 'error',
                 auth.error['description'],
                 'Servicio no disponible.',
                { 
                    iconSize: 35, 
                    iconType: 'solid', 
                    position: 'top right' } 
                );
                global.loading = false;
        });       
    }
  }
}
</script>