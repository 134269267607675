<template>
    <div class="subclass-effdt-index">        
        <LoadingSpinner  v-if="loading" />                           
        <div  v-if="!loading">
            <h1 class="bg-principal text-white text-left pl-5">Subclases</h1>
            <DataTable :headers="headers" :rows="rows" :actions="actions"/>    
            <action-bar :back_label="'Volver'"/>        
        </div>
    </div>
</template>
<script>
import { useSubclassStore } from "@/stores/SubclassStore"
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import DataTable from '@/components/DataTable.vue'
import { useRoute } from 'vue-router'
import ActionBar from '@/components/ActionBar.vue'

export default {
    name: 'SubclassEffdtView',
    components:{
        LoadingSpinner,
        DataTable,
        ActionBar
    },
    data(){
        return {
            subclasses: [],            
            error: false,
            error_msg: "",
            loading: true,
            store : useSubclassStore(),
            headers:[],
            rows: [],
            actions: [],
            sectionId: '',
            classId: '',
            subclassId: '',
        }
    },
    mounted(){   
        const route = useRoute();    
        this.sectionId = route.params.sectionId;
        this.classId = route.params.classId;
        this.subclassId = route.params.subclassId;

        this.store = useSubclassStore(); 
        this.headers = ['ID Sección', 'Fecha Efectiva', 'ID Clase', 'ID Subclase', 'Descripción', 'Estado', 'Coeficiente'];

        this.actions['edit'] = this.editRowAction;         

        this.store.fetchSubclassEffdts( this.sectionId, this.classId , this.subclassId ).then(()=>{
            this.loading = false;
            this.subclasses = this.store.getSubclasses;            
            this.subclasses.forEach( el => this.pushRow(el) );
            console.log(this.subclasses);
        });                 
    },
     methods: {
        editRowAction: function(editId){
            this.$router.push('/subclass/' + editId);   
        },               
        pushRow: function(subclass_obj){
            let element = subclass_obj;            
            let row = [];                           
            let effectiveDate = element.effectiveDate.getDate() + '/'+ (element.effectiveDate.getMonth()+1)+'/'+element.effectiveDate.getFullYear();
            row['edit_id'] = subclass_obj.sectionId + '/' + element.effectiveDateString + '/' + subclass_obj.classId + '/' + subclass_obj.subclassId;                 
            row['data'] = [subclass_obj.sectionId, effectiveDate, subclass_obj.classId, subclass_obj.subclassId,  element.description, element.effectiveStatus , element.coefficient];
            this.rows.push(row);
        }
    }
}
</script>