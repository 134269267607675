<template>
    <div class="subclass-edit">        
        <LoadingSpinner  v-if="loading" />                           
        <div  v-if="!loading">
            <h1 class="bg-principal text-white text-left pl-5">Subclases</h1>
            <div class="flex flex-col">
                <!--<div class="text-left text-xs">Configuración > Sección > Editar </div>-->
                <div>
                    <div class="overflow-hidden mt-4">
                        <form action="">
                            <div class="text-left py-2">
                                <label class="text-left" for="name">ID Sección</label>
                                <input class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" type="text" v-model="store.sectionId" disabled />
                            </div>
                            <div class="text-left py-2">
                                <label class="text-left" for="name">Fecha Efectiva</label>
                                <input class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" type="date" v-model="store.effectiveDateModel" disabled  />
                            </div>
                             <div class="text-left py-2">
                                <label class="text-left" for="name">ID Clase</label>
                                <input class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" type="text" v-model="store.classId" disabled />
                            </div>
                             <div class="text-left py-2">
                                <label class="text-left" for="name">ID SubClase</label>
                                <input class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" type="text" v-model="store.subclassId" disabled />
                            </div>
                            <div class="text-left py-2">
                                <label class="text-left" for="name">Estado</label>
                                <select class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" v-model="store.effectiveStatus">
                                    <option value="A"> Activo </option>
                                    <option value="I"> Inactivo </option>
                                </select>
                            </div>
                            <div class="text-left py-2">
                                <label class="text-left" for="name">Descripción</label>
                                <input class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" type="text" v-model="store.description" />
                            </div>
                            <div class="text-left py-2">
                                <label class="text-left" for="name">Coeficiente</label>
                                <input class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" type="number" step="0.01" v-model="store.coefficient"/>
                            </div>
                            <h1 class="bg-principal text-white text-left pl-5">Productos</h1>
                            <DataTable :headers="headers" :rows="rows" :actions="actions" :pagination="pagination"/>                           
                            <action-bar :custom1_action="addProduct" :custom1_label="'Agregar Producto'" :save_action="save" :save_label="'Guardar'" :back_label="'Volver'"/>
                        </form>
                    </div>
                </div>
            </div>            
        </div> 
        <vue-basic-alert 
        :duration="300" 
        :closeIn="10000"
        ref="saveAlert" />        
    </div>
</template>
<script>
import { useSubclassStore } from "@/stores/SubclassStore"
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import ActionBar from '@/components/ActionBar.vue'

// eslint-disable-next-line
import VueBasicAlert from 'vue-basic-alert'
import { useRoute } from 'vue-router'
import DataTable from '@/components/DataTable.vue'

export default {
    name: 'SubclassEditView',
    components:{
        LoadingSpinner,
        ActionBar,
        DataTable
    },
    data(){
        return {
            sectionId: '',            
            classId: '',
            subclassId: '',
            effectiveDate: "1900-01-01",
            effectiveDateString: "1900-01-01",
            effectiveStatus: "A",
            description: "",
            coefficient: 0,
            error: false,
            error_msg: "",
            loading: true,
            store : useSubclassStore(),
            products: [],
            headers:[],
            rows: [],
            actions: [],
            pagination: {
                total: 0,
                current: 1,
                size: 0,
                refresh: this.refreshProductList
            }
        }
    },
    setup(){
        
    },
    mounted(){ 

        this.headers = ['Origen', 'SKU', 'Nombre'];                
        this.actions['delete'] = this.deleteRowAction;  
        const route = useRoute();    
        this.sectionId = route.params.sectionId;    
        this.effectiveDate = route.params.effectiveDate;
        this.classId = route.params.classId;
        this.subclassId = route.params.subclassId;        
        this.store = useSubclassStore(); 
        this.store.getSubclass(this.sectionId, this.effectiveDate,  this.classId, this.subclassId).then( (success) => {                       
            if(success){                 
                this.effectiveStatus = this.store.effectiveStatus;
                this.effectiveDate = this.store.effectiveDateString.slice(0,10);
                this.effectiveDateString = this.store.effectiveDateString;
                this.description = this.store.description;
                this.coefficient = this.store.coefficient;  
                this.store.fetchProducts().then(()=>{
                    if(success){
                        this.products = this.store.products;
                        this.products.forEach(  el => this.pushRow(el) );   
                        this.pagination.total = this.store.products_total_pages;   
                        this.pagination.size = this.store.products_page_size;
                        this.pagination.current = this.store.products_page;
                        this.loading = false;
                    }
                });                                        
                
            } else {
                this.$refs.saveAlert.showAlert(
                    'error',
                    'No existe la sección.',
                    'Error',
                    { 
                        iconSize: 35, 
                        iconType: 'solid', 
                        position: 'top right' } 
                    );
            }
         });       
    },
     methods: {
        refreshProductList(page){            
            this.store.products_page = page;    
            this.pagination.current = page;            
            this.store.fetchProducts().then(()=>{                    
                        this.rows = [];
                        this.products = this.store.products;
                        this.products.forEach(  el => this.pushRow(el) );   
                        this.pagination.total = this.store.products_total_pages;   
                        this.pagination.size = this.store.products_page_size;
                        this.pagination.current = this.store.products_page;                    
                });  
        },
        deleteRowAction: function(deleteId){
            this.loading = true;
            this.store.deleteProduct(deleteId).then(()=>{
                this.$router.go();     
            });     
        },  
        pushRow: function(obj){
            //let element = obj;            
            let row = [];    
            row['delete_id'] = obj.sectionId + '/' + obj.effectiveDateString + '/' + obj.classId + '/' + obj.subclassId + '/' +obj.source + '/' + obj.sku;    
            row['data'] = [obj.source,  obj.sku, obj.description];
            this.rows.push(row);
        },
        save: function(){                        
            this.store.editSubclass().then((success)=>{
                if(success){
                    this.$refs.saveAlert.showAlert(
                    'success',
                    'La sección fue creada correctamente.',
                    'Éxito',
                    { 
                        iconSize: 35, 
                        iconType: 'solid', 
                        position: 'top right' } 
                    );                                        
                    
                } else {                    
                    this.$refs.saveAlert.showAlert(
                    'error',
                    'No fue posible editar la sección.',
                    'Alerta',
                    { 
                        iconSize: 35, 
                        iconType: 'solid', 
                        position: 'top right' } 
                    );
                }
            });                       
        },
        addProduct: function(){
            var id = this.store.sectionId + '/' + this.store.effectiveDateString + '/' + this.store.classId + '/' + this.store.subclassId;
            this.$router.push('/subclass/' + id + '/addproduct');     
        }
    }
}
</script>

<style scoped>

</style>