<template>
    <div class="section-effdt-index">        
        <LoadingSpinner  v-if="loading" />                           
        <div  v-if="!loading">
            <h1 class="bg-principal text-white text-left pl-5">Sección</h1>
            <DataTable :headers="headers" :rows="rows" :actions="actions"/>    
            <action-bar :back_label="'Volver'"/>        
        </div>
    </div>
</template>
<script>
import { useSectionStore } from "@/stores/SectionStore"
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import DataTable from '@/components/DataTable.vue'
import { useRoute } from 'vue-router'
import ActionBar from '@/components/ActionBar.vue'

export default {
    name: 'SectionEffdtView',
    components:{
        LoadingSpinner,
        DataTable,
        ActionBar
    },
    data(){
        return {
            sections: [],            
            error: false,
            error_msg: "",
            loading: true,
            store : useSectionStore(),
            headers:[],
            rows: [],
            actions: [],
            id: ''
        }
    },
    mounted(){   
        const route = useRoute();    
        this.id = route.params.id;

        this.store = useSectionStore(); 
        this.headers = ['ID', 'Fecha Efectiva', 'Descripción', 'Estado', 'Coeficiente'];

        this.actions['edit'] = this.editRowAction;         

        this.store.fetchSectionEffdts( this.id ).then(()=>{
            this.loading = false;
            this.sections = this.store.getSections;
            
            this.sections.forEach( el => this.pushRow(el) );
        });                 
    },
     methods: {
        editRowAction: function(editId){
            this.$router.push('/section/' + editId);   
        },               
        pushRow: function(section){
            let element = section;            
            let row = [];                           
            let effectiveDate = element.effectiveDate.getDate() + '/'+ (element.effectiveDate.getMonth()+1)+'/'+element.effectiveDate.getFullYear();
            row['edit_id'] = section.sectionId + '/' + element.effectiveDateString;                 
            row['data'] = [section.sectionId, effectiveDate, element.description, element.effectiveStatus , element.coefficient];
            this.rows.push(row);
        }
    }
}
</script>