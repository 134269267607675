<template>
   <div class="subclass-addproduct">        
      <LoadingSpinner  v-if="loading" />         
      <div  v-if="!loading">
         <h1 class="bg-principal text-white text-left pl-5">Subclases - Add Product</h1>
         <div class="flex flex-col">            
            <ul class="grid gap-6 w-full md:grid-cols-7 my-4">
               <li v-for="source in sources" :key="source">
                  <input type="radio" :id="source" name="hosting" :value="source" class="hidden peer" required v-model="selected_source" >
                  <label :for="source" class="inline-flex justify-between items-center p-5 w-full rounded-lg border hover:opacity-75 cursor-pointer peer-checked:border-black peer-checked:border-2" v-bind:class="['bg-'+source+'-primary', 'hover:bg-'+source+'-primary-100']">                                                
                        <div class="block">
                           <img :src="require('@/assets/logos/' + source +'.svg')" class="m-auto h-20 w-20" :alt="source">            
                           <div class="w-full text-white text-sm">{{source}}</div>
                        </div>                        
                  </label>
               </li>               
            </ul>
            <div class="my-4">               
               <div class="relative">
                  <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                        <svg aria-hidden="true" class="w-5 h-5 text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                  </div>
                  <input type="search" id="search" class="block p-4 pl-10 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-0 focus:border-gray-300" placeholder="Buscar, utilice '%' como comodín..." required v-model="search_string">
                  <button type="submit" class="text-white absolute right-2.5 bottom-2.5 bg-principal hover:scale-105 font-medium rounded-lg text-sm px-4 py-2 focus:ring-0 focus:border-gray-300" v-on:click="search(1)">Buscar</button>
               </div>
            </div>
            <div>
               <LoadingSpinner  v-if="loading_search" />
               <DataTable :headers="headers" :rows="rows" :actions="actions" :pagination="pagination" v-if="!loading_search"/>                           
            </div>
            <action-bar :custom1_action="addProducts" :custom1_label="'Agregar Productos'" :back_label="'Volver'"/>
         </div>
      </div>
   </div>
</template>
<script>
import { useSubclassStore } from "@/stores/SubclassStore"
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import ActionBar from '@/components/ActionBar.vue'

// eslint-disable-next-line
import VueBasicAlert from 'vue-basic-alert'
import { useRoute } from 'vue-router'
import DataTable from '@/components/DataTable.vue'

export default {
   name: 'SubclassAddProductView',
   components:{
        LoadingSpinner,
        ActionBar,
        DataTable
    },
    data(){
        return {
            sources: ['jumbo','dafiti','carrefour','coto','fravega', 'easy','manual'], 
            selected_source : 'coto',
            search_string: '',
            loading: true,
            loading_search: true,
            sectionId:'',
            effectiveDate: '',
            effectiveDateString: '',
            classId: '',
            subclassId: '',
            products: [],
            headers:[],
            rows: [],
            actions: [],            
            pagination: {
                total: 0,
                current: 1,
                size: 0,
                refresh: this.search
            }
        }
    },
    mounted(){ 
       this.store = useSubclassStore(); 
       this.headers = ['Nombre', 'Precio', 'Categoría'];
       this.actions['select'] = this.addProduct;  
       const route = useRoute();    
       this.sectionId = route.params.sectionId;
       this.effectiveDate = route.params.effectiveDate;
       this.classId = route.params.classId;
       this.subclassId = route.params.subclassId;
       this.search(1);
       this.loading = false;
    },
   methods: {
      addProduct: function(id){
         this.store.addProduct(id).then((success)=>{
            if(success){
               this.rows = this.rows.filter( data => data.select_id != id);
            } else {
                this.$refs.saveAlert.showAlert(
                    'error',
                    'No se pudo guardar.',
                    'Error',
                    { 
                        iconSize: 35, 
                        iconType: 'solid', 
                        position: 'top right' } 
                    );
            }
         })
      },
      pushRow: function(obj){
            //let element = obj;            
            let row = [];  
            row['select_id'] = this.sectionId + '/' + this.effectiveDate + '/' + this.classId + '/' + this.subclassId + '/' +obj.source + '/' + obj.sku;    
            row['data'] = [obj.name, obj.price, obj.category];
            this.rows.push(row);
        },
      search(page){
         this.store.products_page = page;    
         this.pagination.current = page;           
         this.loading_search = true;
         this.store.products_page = this.pagination.current;
         this.store.fetchProductsToAdd(this.selected_source, this.search_string).then(()=>{
            this.products = this.store.products;
            this.rows = [];
            this.products.forEach(  el => this.pushRow(el) );   
            
            this.pagination.total = this.store.products_total_pages;
            this.pagination.current = this.store.products_page;
            this.pagination.size = this.store.products_page_size;
            this.loading_search = false;
         });  
      }
   }
}
</script>

<style scoped>

</style>