<template>
    <div class="subclass-index">        
        <LoadingSpinner  v-if="loading" />                           
        <div  v-if="!loading">
            <h1 class="bg-principal text-white text-left pl-5">Subclases</h1>
            <DataTable :headers="headers" :rows="rows" :actions="actions"/>
            <action-bar :custom1_action="addSubclass" :custom1_label="'Crear SubClase'" />               
        </div>
    </div>
</template>
<script>
import { useSubclassStore } from "@/stores/SubclassStore"
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import DataTable from '@/components/DataTable.vue'
import ActionBar from '@/components/ActionBar.vue'
 
export default {
    name: 'SubClassView',
    components:{
        LoadingSpinner,
        DataTable,
        ActionBar
    },
    data(){
        return {
            subclasses: [],            
            error: false,
            error_msg: "",
            loading: true,
            store : useSubclassStore(),
            headers:[],
            rows: [],
            actions: [],
        }
    },
    mounted(){   
        this.store = useSubclassStore(); 
        this.headers = ['ID Sección', 'Fecha Efectiva', 'ID Clase', 'ID', 'Descripción', 'Estado', 'Coeficiente'];       
        this.actions['edit'] = this.editRowAction;  
        this.actions['delete'] = this.deleteRowAction; 
        this.actions['detail'] = this.effdtRowAction; 
        
        this.store.fetchSubclasses().then(()=>{
            this.loading = false;
            this.subclasses = this.store.getSubclasses;
            this.subclasses.forEach( el => this.pushRow(el) );            
        });                 
    },
     methods: {
        effdtRowAction: function(id){
            this.$router.push('/subclass/' + id);   
        },
        editRowAction: function(id){
            this.$router.push('/subclass/' + id);   
        },       
        deleteRowAction: function(id){
            this.loading = true;
            this.store.deleteSubclass(id).then(()=>{
                this.$router.go();     
            });      
        },             
      pushRow: function(subclass_obj){
          let element = subclass_obj.rows.pop();            
          let row = [];        
                 
          let effectiveDate = element.effectiveDate.getDate() + '/'+ (element.effectiveDate.getMonth()+1)+'/'+element.effectiveDate.getFullYear();
          row['edit_id'] = subclass_obj.sectionId + '/' + element.effectiveDateString + '/' + subclass_obj.classId + '/' + subclass_obj.subclassId;               
          row['delete_id'] = subclass_obj.sectionId + '/' + subclass_obj.classId + '/' + subclass_obj.subclassId;
          row['detail_id'] = subclass_obj.sectionId + '/' + subclass_obj.classId + '/' + subclass_obj.subclassId;
          row['data'] = [subclass_obj.sectionId, effectiveDate, subclass_obj.classId,  subclass_obj.subclassId, element.description, element.effectiveStatus , element.coefficient];
          this.rows.push(row);
      },
       addSubclass: function(){            
            this.$router.push('/subclasses/add');   
        }
    }
}
</script>