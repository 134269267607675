<template>
    <div class="h-full shadow-lg bg-gradient-to-br from-principal via-stone-800 to-principal rounded-r-xl relative" id="navbar_aside" v-bind:class="(navbarExpanded)?'w-60':'w-10'">
        <div class="pt-4 pb-2 px-2" v-bind:class="(navbarExpanded)?'':'hidden ease-in duration-300'">
            <div class="flex-1 items-center">
                <div class="">
                    <img :src="require('@/assets/img/avatar.png')" class="rounded-full w-10 m-auto" alt="Avatar">
                </div>
                <div class="">
                        <p class="text-sm uppercase font-semibold text-white">{{user.unique_name}}</p> 
                </div>
                <div class="">
                    <p class="text-xs uppercase text-white">{{user.email}}</p>  
                </div>         
            </div>
        </div>
        <ul class="relative px-1" v-bind:class="(navbarExpanded)?'':'hidden ease-in duration-300'">     
            <li class="relative" v-for="itemnav in store.getNavItems" :key="itemnav.path">
                <a class="cursor-pointer flex items-center text-sm py-4 px-6 h-12 overflow-hidden text-gray-400 text-ellipsis whitespace-nowrap rounded hover:text-gray-200 transition duration-300 ease-in-out opacity-75 hover:opacity-100"
                  v-on:click="store.go(itemnav)" data-mdb-ripple="true" data-mdb-ripple-color="primary"
                 data-bs-toggle="collapse" 
                 :aria-controls="itemnav.id+'-subitems'" :data-collapse-toggle="itemnav.id+'-subitems'">                    
                    <img :src="require('@/assets/icons/' + itemnav.icon )" class="w-5 h-5 m-2"/>
                    <span>{{itemnav.description}}</span>
                     <svg aria-hidden="true" focusable="false" data-prefix="fas" class="w-3 h-3 ml-auto" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" v-if="!(itemnav.subitems.length === 0)">
                        <path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
                    </svg>  
                </a>
                <ul :id="itemnav.id+'-subitems'" class="collapse hidden">
                    <li class="relative" v-for="subitem in itemnav.subitems" :key="subitem.path">
                        <a v-on:click="store.go(subitem)" class="cursor-pointer flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-400 text-ellipsis whitespace-nowrap rounded hover:text-gray-200 transition duration-300 ease-in-out" 
                        data-mdb-ripple="true" data-mdb-ripple-color="primary">
                        {{subitem.description}}</a>
                    </li>
                </ul>
            </li>       
        </ul>
        <div class="absolute bottom-0 right-0 cursor-pointer" v-on:click="toggleExpand()" v-bind:class="(navbarExpanded)?'rotate-180':''">
            <img :src="require('@/assets/icons/arrow-right.svg')" class="w-5 h-5 m-2"/>                    
        </div>
  </div>
</template>
<script>
import { useNavStore } from "@/stores/NavStore"
import { useAuthStore } from "@/stores/AuthStore"

export default {
    name:'NavbarAside',
    data(){
        return {
            store: useNavStore(),
            user: useAuthStore(),
            navbarExpanded: (window.innerWidth>767)
        }
    },
    methods: {
        toggleExpand() {
            this.navbarExpanded = !this.navbarExpanded
        }
    }
}
</script>
