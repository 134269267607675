import {defineStore} from 'pinia'
import axios from "axios"

export const useIndexStore  = defineStore(
    {   id: "IndexStore",    
        state: ()=>({                       
                data: [] ,
                indexes: [],
                fileData: null,                
        }), 
        getters:{
            getIndexes(state){                
               return state.indexes
            },
            getFileData(state){
                return state.fileData;
            }
        },
        actions:{                        
            fetchIndex( el ){                        
                this.indexes.push({
                    indexDate : new Date(el.indexDate),
                    indexDateString: el.indexDate,
                    indexDateModel: el.indexDate.slice(0,10),
                    status: el.status,
                    amount: el.amount                    
                });       
            },           
            async fetchIndexes() {               
                try {                
                  const data = await axios.get('/Index?PageSize=100');                  
                  this.data = data.data.data;         
                  this.indexes = [];
                  this.data.forEach( e => this.fetchIndex(e));
                  this.indexes.sort( (a,b) => { return b.indexDate < a.indexDate}  );       
                  }
                  catch (error) {
                    console.log(error)
               }
            },  
            async downloadExcel(id){
                try {
                    const data = await axios.get('/Index/' + id , {
                        responseType: 'blob'
                    });    
                    console.log(data);
                    this.fileData = data.data;
                } catch (error) {
                    console.log(error)
               }
            },
            async runIndex(id){
                try {
                    await axios.post('/Index/Add/' + id);                        
                } catch (error) {
                    console.log(error)
               }
            }
        }                      
    }    
)