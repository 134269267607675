<template>
    <div class="mt-24 ">
        <div class="p-2 text-left fixed bottom-1 w-screen bg-white">
            <button type="button" class="mx-4 text-white bg-principal hover:scale-105 font-medium rounded-lg text-xs px-5 py-2.5 text-center inline-flex items-center" v-on:click="custom1_action" v-if="custom1_action" >
                {{custom1_label}}            
            </button>
            <button type="button" class="mx-4 text-white bg-principal hover:scale-105 font-medium rounded-lg text-xs px-5 py-2.5 text-center inline-flex items-center" v-on:click="save_action" v-if="save_action" >
                {{save_label}}
                <img :src="require('@/assets/icons/save.svg')" class="ml-2 -mr-1 w-4 h-4"  />
            </button>
            <button type="button" class="mx-4 border border-gray-400 hover:scale-105 font-medium rounded-lg text-xs px-5 py-2.5 text-center inline-flex items-center" v-on:click="back()" v-if="back_label">
                {{back_label}}
                <img :src="require('@/assets/icons/return.svg')" class="ml-2 -mr-1 w-4 h-4"  />
            </button>                                
        </div>
    </div>
</template>


<script>
export default {
    name: 'ActionBar',
    props: {
        save_label: String,
        save_action: Function,
        back_label: String,  
        back_action: Function,
        custom1_label: String,
        custom1_action: Function,        
    },
    methods: {       
        back() {
            if(this.back_action){
                this.back_action();
            } else {
                this.$router.go(-1);
            }            
        },
    },
}
</script>