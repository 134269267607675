<template>
    <div class="class-edit">        
        <LoadingSpinner  v-if="loading" />                           
        <div  v-if="!loading">
            <h1 class="bg-principal text-white text-left pl-5">Clases</h1>
            <div class="flex flex-col">
                <!--<div class="text-left text-xs">Configuración > Sección > Editar </div>-->
                <div>
                    <div class="overflow-hidden mt-4">
                        <form action="">
                            <div class="text-left py-2">
                                <label class="text-left" for="name">ID Sección</label>
                                <input class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" type="text" v-model="store.sectionId" disabled />
                            </div>
                            <div class="text-left py-2">
                                <label class="text-left" for="name">Fecha Efectiva</label>
                                <input class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" type="date" v-model="store.effectiveDateModel" disabled  />
                            </div>
                             <div class="text-left py-2">
                                <label class="text-left" for="name">ID Clase</label>
                                <input class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" type="text" v-model="store.classId" disabled />
                            </div>
                            <div class="text-left py-2">
                                <label class="text-left" for="name">Estado</label>
                                <select class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" v-model="store.effectiveStatus">
                                    <option value="A"> Activo </option>
                                    <option value="I"> Inactivo </option>
                                </select>
                            </div>
                            <div class="text-left py-2">
                                <label class="text-left" for="name">Descripción</label>
                                <input class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" type="text" v-model="store.description" />
                            </div>
                            <div class="text-left py-2">
                                <label class="text-left" for="name">Coeficiente</label>
                                <input class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" type="number" step="0.01" v-model="store.coefficient"/>
                            </div>   
                            <h1 class="bg-principal text-white text-left pl-5">Subclases</h1>
                            <DataTable :headers="headers" :rows="rows" :actions="actions"/>
                                    
                            <action-bar :save_action="save" :save_label="'Guardar'" :back_label="'Volver'"/>
                        </form>
                    </div>
                </div>
            </div>            
        </div> 
        <vue-basic-alert 
        :duration="300" 
        :closeIn="10000"
        ref="saveAlert" />        
    </div>
</template>
<script>
import { useClassStore } from "@/stores/ClassStore"
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import ActionBar from '@/components/ActionBar.vue'
import DataTable from '@/components/DataTable.vue'

// eslint-disable-next-line
import VueBasicAlert from 'vue-basic-alert'
import { useRoute } from 'vue-router'

export default {
    name: 'ClassEditView',
    components:{
        LoadingSpinner,
        ActionBar,
        DataTable
    },
    data(){
        return {
            sectionId: '',            
            classId: '',
            effectiveDate: "1900-01-01",
            effectiveDateString: "1900-01-01",
            effectiveStatus: "A",
            description: "",
            coefficient: 0,
            subclasses: [],
            error: false,
            error_msg: "",
            loading: true,
            store : useClassStore(),
            headers:[],
            rows: [],
            actions: [],
        }
    },
    setup(){
        
    },
    mounted(){ 

        this.headers = [ 'ID Subclase','Descripción', 'Estado', 'Coeficiente'];       
        this.actions['edit'] = this.editRowAction; 

        const route = useRoute();    
        this.sectionId = route.params.sectionId;    
        this.effectiveDate = route.params.effectiveDate;
        this.classId = route.params.classId;
        this.store = useClassStore(); 
        this.store.getClass(this.sectionId, this.effectiveDate,  this.classId).then( (success) => {                       
            if(success){                 
                this.effectiveStatus = this.store.effectiveStatus;
                this.effectiveDate = this.store.effectiveDateString.slice(0,10);
                this.effectiveDateString = this.store.effectiveDateString;
                this.description = this.store.description;
                this.coefficient = this.store.coefficient;                
                this.subclasses = this.store.subclasses;
                this.subclasses.forEach( el => this.pushRow(el) );
                this.loading = false;
            } else {
                this.$refs.saveAlert.showAlert(
                    'error',
                    'No existe la sección.',
                    'Error',
                    { 
                        iconSize: 35, 
                        iconType: 'solid', 
                        position: 'top right' } 
                    );
            }
         });       
    },
     methods: {
         pushRow: function(subclass_obj){
            let element = subclass_obj;            
            let row = [];        
            row['edit_id'] = subclass_obj.sectionId + '/' + element.effectiveDateString + '/' + subclass_obj.classId + '/' + subclass_obj.subclassId;               
            row['data'] = [  subclass_obj.subclassId, element.description, element.effectiveStatus , element.coefficient];
            this.rows.push(row);
        },
        editRowAction: function(id){
           this.$router.push('/subclass/' + id);  
        },
        save: function(){                        

            this.store.editClass().then((success)=>{
                if(success){
                    this.$refs.saveAlert.showAlert(
                    'success',
                    'La sección fue creada correctamente.',
                    'Éxito',
                    { 
                        iconSize: 35, 
                        iconType: 'solid', 
                        position: 'top right' } 
                    );                                        
                    
                } else {                    
                    this.$refs.saveAlert.showAlert(
                    'error',
                    'No fue posible editar la sección.',
                    'Alerta',
                    { 
                        iconSize: 35, 
                        iconType: 'solid', 
                        position: 'top right' } 
                    );
                }
            });                       
        }
    }
}
</script>

<style scoped>

</style>