<template>
    <popup-modal ref="popup">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div  class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-principal sm:mx-0 sm:h-10 sm:w-10">
                <!-- Heroicon name: outline/exclamation -->
               <img :src="icon" class="m-auto h-5 w-5" title="Borrar">  
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-headline"
                >
                  {{ title }}
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    {{ message }}
                  </p>
                  <slot></slot>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              @click="_confirm"
              class="mx-2 text-white bg-principal hover:scale-105 font-medium rounded-lg text-xs px-5 py-2.5 text-center inline-flex items-center"
            >
              {{okButton}}
            </button>
            <button
              type="button"
              @click="_cancel"
              class="mx-2 border border-gray-400 hover:scale-105 font-medium rounded-lg text-xs px-5 py-2.5 text-center inline-flex items-center"                      
            >
              {{cancelButton}}
            </button>
          </div>       
    </popup-modal>
</template>

<script>
import PopupModal from './PopupModal.vue'

export default {
    name: 'ConfirmDialogue',

    components: { PopupModal },

    data: () => ({
        // Parameters that change depending on the type of dialogue
        title: undefined,
        message: undefined, // Main text content
        okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
        cancelButton: 'Cancelar', // text for cancel button
        icon : require('@/assets/icons/alert.svg'),
        // Private variables
        resolvePromise: undefined,
        rejectPromise: undefined,
    }),

    methods: {
        show(opts = {}) {
            this.title = opts.title
            this.message = opts.message
            this.okButton = opts.okButton
            if (opts.cancelButton) {
                this.cancelButton = opts.cancelButton;
            }
            if (opts.icon) {
              this.icon = opts.icon;
            }
            // Once we set our config, we tell the popup modal to open
            this.$refs.popup.open();
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },

        _confirm() {
            this.$refs.popup.close()
            this.resolvePromise(true)
        },

        _cancel() {
            this.$refs.popup.close()
            this.resolvePromise(false)
            // Or you can throw an error
            // this.rejectPromise(new Error('User cancelled the dialogue'))
        },
    },
}
</script>