import {defineStore} from 'pinia'
import axios from "axios"

export const useSectionStore  = defineStore(
    {   id: "SectionStore",    
        state: ()=>({                       
                data: [] ,
                sections: [],
                id: '',
                effectiveDate: null,
                effectiveDateString: '',
                effectiveDateModel: '',
                effectiveStatus: '',           
                description: '',
                coefficient: 0,
                classes: []
        }), 
        getters:{
            getSections(state){                
               return state.sections
            }
        },
        actions:{  
            setSectionDefaults(){
                this.id = '';
                this.effectiveDate = new Date('1900-01-01T00:00:00');
                this.effectiveDateString = '1900-01-01T00:00:00';
                this.effectiveDateModel = '1900-01-01';
                this.effectiveStatus = 'A';
                this.description = '';
                this.coefficient = 0;
            },                 
            fetchSection( section ){
                let row = this.sections.find( sec => sec.sectionId == section.sectionId );
                if(row ==undefined){
                    let initrow = {
                        sectionId : section.sectionId,
                        rows : []
                    };
                    this.sections.push(initrow);
                    row = this.sections.find( sec => sec.sectionId == section.sectionId );
                }                
                row.rows.push({
                    effectiveDate : new Date(section.effectiveDate),
                    effectiveDateString: section.effectiveDate,
                    effectiveDateModel: section.effectiveDate.slice(0,10),
                    description: section.description,
                    effectiveStatus: section.effectiveStatus,
                    coefficient: section.coefficient
                });       
            },           
            async fetchSections() {               
                try {                
                  const data = await axios.get('/IndexSetting/Section');                  
                  this.data = data.data.data;                  
                  this.sections = [];
                  this.data.forEach( e => this.fetchSection(e));
                  this.sections.forEach( e => e.rows.sort( (a,b) => { return b.effectiveDate < a.effectiveDate}  ) );       
                  }
                  catch (error) {
                    console.log(error)
               }
            },
            async addSection(){
                try {
                    let dataToSend = {
                        sectionId: this.id,
                        effectiveDate: this.effectiveDateString,
                        effectiveStatus: this.effectiveStatus,
                        description: this.description,
                        coefficient: this.coefficient
                    }               
                    const data = await axios.post('/IndexSetting/Section/Add', dataToSend);                  
                    this.data = data.data.data;
                    return true;
                }
                    catch (error) {
                    console.log(error);
                    return false;
                }
            },            
            async getSection(id, effectiveDate){
                try {                
                    const data = await axios.get('/IndexSetting/Section/'+id+'/'+effectiveDate);                       
                    this.id = data.data.data[0].sectionId;
                    this.effectiveDate = new Date(data.data.data[0].effectiveDate);
                    this.effectiveDateString = data.data.data[0].effectiveDate;
                    this.effectiveDateModel = this.effectiveDateString.slice(0,10);
                    this.effectiveStatus = data.data.data[0].effectiveStatus;
                    this.description = data.data.data[0].description;
                    this.coefficient = data.data.data[0].coefficient;
                    this.classes = data.data.data[0].classes;  
                    this.classes.forEach((el)=>{
                        el.effectiveDateString = el.effectiveDate;                     
                        el.effectiveDate = new Date(el.effectiveDateString);
                        el.effectiveDateModel = el.effectiveDateString.slice(0,10);
                    });                  
                    return true;
                }
                    catch (error) {
                    console.log(error);
                    return false;
                }
            },
            async editSection(){
                try {         
                    let dataToSend = {
                        sectionId: this.id,
                        effectiveDate: this.effectiveDateString,
                        effectiveStatus: this.effectiveStatus,
                        description: this.description,
                        coefficient: this.coefficient
                    }       
                    const data = await axios.post('/IndexSetting/Section/Edit', dataToSend);                  
                    this.data = data.data.data;
                    return true;
                }
                    catch (error) {
                    console.log(error);
                    return false;
                }
            },
            async deleteSection(id){
                try {
                    await axios.delete('/IndexSetting/Section/' + id);                                          
                    return true;
                }
                    catch (error) {
                    console.log(error);
                    return false;
                }
            },
            async addEffdtSection(id){
                try {
                    await axios.put('/IndexSetting/Section/' + id);                                          
                    return true;
                }
                    catch (error) {
                    console.log(error);
                    return false;
                }
            },     
            fetchSectionEffdt( section ){
                this.sections.push({
                    sectionId : section.sectionId,
                    effectiveDate : new Date(section.effectiveDate),
                    effectiveDateString: section.effectiveDate,
                    description: section.description,
                    effectiveStatus: section.effectiveStatus,
                    coefficient: section.coefficient
                });
            },      
            async fetchSectionEffdts(id) {               
                try {                
                  const data = await axios.get('/IndexSetting/Section/'+id);     
                  this.data = data.data.data;
                  this.sections = [];
                  this.data.forEach( e => this.fetchSectionEffdt(e));
                  this.sections.forEach( e => e.rows.sort( (b,a) => { return b.effectiveDate < a.effectiveDate}  ) );       
                  }
                  catch (error) {
                    console.log(error)
               }
            },                       
        }                      
    }    
)