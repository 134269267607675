import {defineStore} from 'pinia'
import axios from "axios"

export const useAuthStore  = defineStore(
    {   id: "UserStore",         
        state: ()=>({           
                autenticated : false, 
                token: "",
                unique_name: "",
                email: "",
                expiration: 0,
                error : [],  
        }), 
        getters:{
            isAutenticated(){
                this.reset();                         
                return this.autenticated;
            },
            
        },
        actions:{    
            logout(){                
                localStorage.removeItem("indexAppToken");
            },
            async login(user, password, callbackSuccess = function(){}, callbackNoSuccess = function(){}, callbackError = function(){}){
                this.error = [];
                let loginRequest = {
                    "user": user,
                    "password": password
                };
                axios.post('/Auth/login', loginRequest)      
                    .then(data => {
                        if(data.data.succeeded == true ){
                            localStorage.setItem("indexAppToken",  data.data.data[0].token);
                            axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.indexAppToken;	
                            this.isAutenticated;
                            callbackSuccess();    
                        } else {    
                            this.error['code'] = 'INVALID';
                            this.error['description'] = 'Usuario y/o contraseña inválidos.';
                            callbackNoSuccess();
                        }
                        this.loading = false;
                        }            
                    ).catch(
                        data => {
                            var description = "Error 404";
                            if( data.code !== undefined && data.message !== undefined ){
                                description = data.code + ": " + data.message + ".";
                            }
                            this.error['code'] = 'SERVICE_UNAVAILABLE';
                            this.error['description'] = description;
                            callbackError();                                                                                                
                        }
                    );
            },
            reset(){
                if (localStorage.getItem("indexAppToken") === null){
                    this.token = "";
                    this.user = "";
                } else {
                    this.token = localStorage.getItem("indexAppToken");
                    var base64Url = this.token.split('.')[1];
                    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    }).join(''));
                    var parsedToken = JSON.parse(jsonPayload) ;
                    this.unique_name = parsedToken.unique_name;
                    this.email = parsedToken.email;
                    this.expiration = parsedToken.exp * 1000;
                }             
                this.autenticated = (typeof this.token !== 'undefined' && this.token && Date.now() <= this.expiration);
            }
        }                      
    }    
)