import {defineStore} from 'pinia'
import axios from "axios"
 
export const useSubclassStore  = defineStore(
    {   id: "SubclassStore",    
        state: ()=>({                       
                data: [] ,
                subclasses: [],
                sectionId: '',
                classId: '',
                subclassId: '',
                effectiveDate: null,
                effectiveDateString: '',
                effectiveDateModel: '',
                effectiveStatus: '',           
                description: '',
                coefficient: 0,
                products: [],                
                products_page: 1,
                products_total_pages: 0,
                products_page_size: 0               
        }), 
        getters:{
            getSubclasses(state){                
               return state.subclasses
            }
        },
        actions:{  
            setSubclassDefaults(){
                this.sectionId ='';
                this.classId = '';
                this.subclassId = '';
                this.effectiveDate = new Date('1900-01-01T00:00:00');
                this.effectiveDateString = '1900-01-01T00:00:00';
                this.effectiveDateModel = '1900-01-01';
                this.effectiveStatus = 'A';
                this.description = '';
                this.coefficient = 0;
            },                 
            fetchSubclass( subclass_obj ){

               
                let row = this.subclasses.find( obj => obj.sectionId == subclass_obj.sectionId && obj.classId == subclass_obj.classId && obj.subclassId == subclass_obj.subclassId);
                if(row == undefined){
                    let initrow = {
                        sectionId : subclass_obj.sectionId,                        
                        classId: subclass_obj.classId,
                        subclassId: subclass_obj.subclassId,
                        rows : []
                    };
                    this.subclasses.push(initrow);
                    row = this.subclasses.find( obj => obj.sectionId == subclass_obj.sectionId && obj.classId == subclass_obj.classId && obj.subclassId == subclass_obj.subclassId);
                }                

                row.rows.push({
                    effectiveDate : new Date(subclass_obj.effectiveDate),
                    effectiveDateString: subclass_obj.effectiveDate,
                    effectiveDateModel: subclass_obj.effectiveDate.slice(0,10),
                    description: subclass_obj.description,
                    effectiveStatus: subclass_obj.effectiveStatus,
                    coefficient: subclass_obj.coefficient
                });       
            },           
            async fetchSubclasses() {               
                try {                
                  const data = await axios.get('/IndexSetting/Subclass');                  
                  this.data = data.data.data;
                  this.subclasses = [];
                  this.data.forEach( e => this.fetchSubclass(e));
                  this.subclasses.forEach( e => e.rows.sort( (a,b) => { return b.effectiveDate < a.effectiveDate}  ) );       
                  }
                  catch (error) {
                    console.log(error)
               }
            },
            async addSubclass(){
                try {
                    let dataToSend = {
                        sectionId: this.sectionId,
                        effectiveDate: this.effectiveDateString,
                        classId: this.classId,
                        subclassId: this.subclassId,
                        effectiveStatus: this.effectiveStatus,
                        description: this.description,
                        coefficient: this.coefficient
                    }               
                    const data = await axios.post('/IndexSetting/Subclass/Add', dataToSend);                  
                    this.data = data.data.data;
                    return true;
                }
                    catch (error) {
                    console.log(error);
                    return false;
                }
            },            
            async getSubclass(sectionId, effectiveDate, classId, subclassId){
                try {                
                    const data = await axios.get('/IndexSetting/Subclass/'+sectionId+'/'+effectiveDate+'/'+classId+'/'+subclassId);    
                    this.sectionId = data.data.data[0].sectionId;
                    this.effectiveDate = new Date(data.data.data[0].effectiveDate);
                    this.effectiveDateString = data.data.data[0].effectiveDate;
                    this.effectiveDateModel = this.effectiveDateString.slice(0,10);
                    this.classId = data.data.data[0].classId;
                    this.subclassId = data.data.data[0].subclassId;
                    this.effectiveStatus = data.data.data[0].effectiveStatus;
                    this.description = data.data.data[0].description;
                    this.coefficient = data.data.data[0].coefficient;
                    return true;
                }
                    catch (error) {
                    console.log(error);
                    return false;
                }
            },
            async editSubclass(){
                try {         
                    let dataToSend = {
                        sectionId: this.sectionId,
                        effectiveDate: this.effectiveDateString,
                        classId: this.classId,
                        subclassId: this.subclassId,
                        effectiveStatus: this.effectiveStatus,
                        description: this.description,
                        coefficient: this.coefficient
                    }       
                    const data = await axios.post('/IndexSetting/Subclass/Edit', dataToSend);                  
                    this.data = data.data.data;
                    return true;
                }
                    catch (error) {
                    console.log(error);
                    return false;
                }
            },
            async deleteSubclass(id){
                try {
                    await axios.delete('/IndexSetting/Subclass/' + id);                                          
                    return true;
                }
                    catch (error) {
                    console.log(error);
                    return false;
                }
            },  
            fetchSubclassEffdt( subclass_obj ){
                this.subclasses.push({
                    sectionId : subclass_obj.sectionId,
                    classId: subclass_obj.classId,
                    subclassId: subclass_obj.subclassId,
                    effectiveDate : new Date(subclass_obj.effectiveDate),
                    effectiveDateString: subclass_obj.effectiveDate,
                    description: subclass_obj.description,
                    effectiveStatus: subclass_obj.effectiveStatus,
                    coefficient: subclass_obj.coefficient
                });
            },      
            async fetchSubclassEffdts(sectionId, classId, subclassId) {               
                try {                
                  const data = await axios.get('/IndexSetting/Subclass/'+sectionId+'/'+classId+'/'+subclassId);     
                  this.data = data.data.data;
                  this.subclasses = [];
                  this.data.forEach( e => this.fetchSubclassEffdt(e));
                  this.subclasses.forEach( e => e.rows.sort( (b,a) => { return b.effectiveDate < a.effectiveDate}  ) );       
                  }
                  catch (error) {
                    console.log(error)
               }
            },                
            fetchProduct( obj ){                                          
                    obj.effectiveDateString= obj.effectiveDate;
                    obj.effectiveDate = new Date(obj.effectiveDate);                         
            },      
            async fetchProducts() {               
                try {                
                  const data = await axios.get('/IndexSetting/Product/Get/'+this.sectionId+'/'+this.effectiveDateString+'/'+this.classId+'/'+this.subclassId+"?PageNumber="+this.products_page);                  
                  this.products = data.data.data;
                  this.products_page = data.data.pageNumber;
                  this.products_page_size = data.data.pageSize;
                  this.products_total_pages = data.data.totalPages;                  
                  this.products.forEach( e => this.fetchProduct(e));                 
                  }
                  catch (error) {
                    console.log(error)
               }
            },
            async deleteProduct(id) {
                try {
                    await axios.delete('/IndexSetting/Product/' + id);                                          
                    return true;
                }
                    catch (error) {
                    console.log(error);
                    return false;
                }
            },
            fetchProductToAdd( obj ){                                          
                obj.effectiveDateString= obj.effectiveDate;
                obj.effectiveDate = new Date(obj.effectiveDate);
                obj.effectiveDateString= obj.effectiveDate;                          
             },      
            async fetchProductsToAdd(source, searchString) {               
            try {   
              if(searchString== ""){
                searchString = "%";
              } 
              const data = await axios.get('/IndexSetting/Subclass/SearchProductForAdd/'+source+'/'+searchString+"?PageNumber="+this.products_page);                  
              this.products = data.data.data;
              this.products_page = data.data.pageNumber;
              this.products_page_size = data.data.pageSize;
              this.products_total_pages = data.data.totalPages;             
              this.products.forEach( e => this.fetchProductToAdd(e));                 
              }
              catch (error) {
                console.log(error)
              }
            },
            async addProduct(id) {               
                try {                   
                    await axios.post('/IndexSetting/Product/Add/'+id);                  
                    return true;
                  }
                  catch (error) {
                    console.log(error);
                    return false;
                
                  }
                },
        }                      
    }    
)