import {defineStore} from 'pinia'
import axios from "axios"

export const useProductStore  = defineStore(
    {   id: "ProductStore",    
        state: ()=>({           
                data: [] ,
                source: '',
                sourceDate: null,
                errors: '',
                message: '',
                pageNumber: null,
                pageSize: null,
                totalPages: null,
                totalRecords: null,
                product: {},           
        }), 
        getters:{
            getProducts(state){
               return state.data
            }
        },
        actions:{
            async fetchProducts() {                               
                try {              
                  var pagination = [];
                  if(this.pageNumber){
                    pagination.push("pageNumber="+this.pageNumber);
                  }
                  if(this.pageSize){
                    pagination.push("pageSize="+this.pageSize);
                  }
                  var paginationQuery = "";
                  if(pagination.length>0){
                    paginationQuery = "/?" + pagination.join("&");
                  }
                  const data = await axios.get('/Product/'+this.source+'/'+this.sourceDate+paginationQuery);                  
                  this.data = data.data.data;
                  this.pageNumber = data.data.pageNumber;
                  this.pageSize = data.data.pageSize;
                  this.totalPages = data.data.totalPages;
                  this.totalRecords = data.data.totalRecords;                  
                  }
                  catch (error) {
                    console.log(error)
               }
            },
            setSource(source, sourceDate){
                this.source = source;
                this.sourceDate = sourceDate;
            },
            setPage(pageNumber){
                this.pageNumber = pageNumber;
            },
            setPageSize(pageSize){
                this.pageSize = pageSize;
            },
            async addFromFile(file){
              try {    
                let formData = new FormData();
                formData.append('file', file);            
                const data = await axios.post('/ProductExcel/', formData, {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
                });     
                if( !data.data.succeeded) {
                  this.message = data.data.message;
                }     
                }
                catch (error) {
                  console.log(error)
             }            
            }
        }                      
    }    
)