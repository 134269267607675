<template>
    <div class="class-index">        
        <LoadingSpinner  v-if="loading" />                           
        <div  v-if="!loading">
            <h1 class="bg-principal text-white text-left pl-5">Clases</h1>
            <DataTable :headers="headers" :rows="rows" :actions="actions"/>
            <action-bar :custom1_action="addClass" :custom1_label="'Crear Clase'" />               
        </div>
    </div>
</template>
<script>
import { useClassStore } from "@/stores/ClassStore"
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import DataTable from '@/components/DataTable.vue'
import ActionBar from '@/components/ActionBar.vue'
 
export default {
    name: 'ClassView',
    components:{
        LoadingSpinner,
        DataTable,
        ActionBar
    },
    data(){
        return {
            classes: [],            
            error: false,
            error_msg: "",
            loading: true,
            store : useClassStore(),
            headers:[],
            rows: [],
            actions: [],
        }
    },
    mounted(){   
        this.store = useClassStore(); 
        this.headers = ['ID Sección', 'Fecha Efectiva', 'ID', 'Descripción', 'Estado', 'Coeficiente'];       
        this.actions['edit'] = this.editRowAction;  
        this.actions['delete'] = this.deleteRowAction; 
        this.actions['detail'] = this.effdtRowAction; 
        
        this.store.fetchClasses().then(()=>{
            this.loading = false;
            this.classes = this.store.getClasses;
            this.classes.forEach( el => this.pushRow(el) );            
        });                 
    },
     methods: {
        effdtRowAction: function(id){
            this.$router.push('/class/' + id);   
        },
        editRowAction: function(id){
            this.$router.push('/class/' + id);   
        },       
        deleteRowAction: function(id){
            this.loading = true;
            this.store.deleteClass(id).then(()=>{
                this.$router.go();     
            });      
        },             
      pushRow: function(class_obj){
          let element = class_obj.rows.pop();            
          let row = [];        
                 
          let effectiveDate = element.effectiveDate.getDate() + '/'+ (element.effectiveDate.getMonth()+1)+'/'+element.effectiveDate.getFullYear();
          row['edit_id'] = class_obj.sectionId + '/' + element.effectiveDateString + '/' + class_obj.classId;               
          row['delete_id'] = class_obj.sectionId + '/' + class_obj.classId;
          row['detail_id'] = class_obj.sectionId + '/' + class_obj.classId;
          row['data'] = [class_obj.sectionId, effectiveDate, class_obj.classId,  element.description, element.effectiveStatus , element.coefficient];
          this.rows.push(row);
      },
       addClass: function(){            
            this.$router.push('/classes/add');   
        }
    }
}
</script>