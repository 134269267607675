<template>
    <div class="class-add">       
        <LoadingSpinner  v-if="loading" />                           
        <div  v-if="!loading">
            <h1 class="bg-principal text-white text-left pl-5">Clases</h1>
            <div class="flex flex-col">
                <!--<div class="text-left text-xs">Configuración > Sección > Agregar </div>-->
                <div>
                    <div class="overflow-hidden mt-4">
                        <form action="">
                            <div class="text-left py-2">
                                <label class="text-left" for="name">ID Sección</label>   
                                <v-select :options="section_options" class="text-xs" v-model="store.sectionId" :reduce="option => option.code" >
                                    <template v-slot:no-options>... No hay valores coincidentes :'( </template>    
                                </v-select>                                                             
                            </div>
                            <div class="text-left py-2">
                                <label class="text-left" for="name">Fecha Efectiva</label>
                                <v-select :options="section_effdts[store.sectionId]" class="text-xs" :value="store.effectiveDateString">
                                    <template v-slot:no-options>... No hay valores coincidentes :'( </template>    
                                </v-select>                                  
                            </div>
                            <div class="text-left py-2">
                                <label class="text-left" for="name">ID Clase</label>
                                <input class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" type="text" v-model="store.classId" />
                            </div>
                            <div class="text-left py-2">
                                <label class="text-left" for="name">Estado</label>
                                <select class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" v-model="store.effectiveStatus">
                                    <option value="A"> Activo </option>
                                    <option value="I"> Inactivo </option>
                                </select>
                            </div>
                            <div class="text-left py-2">
                                <label class="text-left" for="name">Descripción</label>
                                <input class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" type="text" v-model="store.description" />
                            </div>
                            <div class="text-left py-2">
                                <label class="text-left" for="name">Coeficiente</label>
                                <input class="w-full p-3 text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" type="number" step="0.01" v-model="store.coefficient"/>
                            </div>
                            <action-bar :save_action="addClass" :save_label="'Guardar'" :back_label="'Cancelar'"/>                            
                        </form>
                    </div>
                </div>
            </div>            
        </div> 
        <vue-basic-alert 
        :duration="300" 
        :closeIn="10000"
        ref="saveAlert" />        
    </div>
</template>
<script>
import { useClassStore } from "@/stores/ClassStore"
import { useSectionStore } from "@/stores/SectionStore"
import LoadingSpinner from '@/components/LoadingSpinner.vue'
// eslint-disable-next-line
import VueBasicAlert from 'vue-basic-alert'
import ActionBar from '@/components/ActionBar.vue'


export default {
    name: 'ClassAddView',
    components:{
        LoadingSpinner,
        ActionBar
    },
    data(){
        return {                     
            loading: true,
            store : useClassStore(), 
            sectionStore : useSectionStore(),
            section_effdts: [],
            section_options: []               
        }
    },
    mounted(){               
         this.store = useClassStore(); 
         this.store.setClassDefaults();   

         this.sectionStore = useSectionStore();
         this.sectionStore.fetchSections().then(()=>{
            this.section_options = [];
            this.section_effdts = [];
            this.sectionStore.getSections.forEach( e => this.fetchSection(e));    
            console.log(this.section_effdts) ;
            this.loading = false;
        });   
                      
    },
     methods: {
        fetchSection: function(section){
            this.section_options.push({label: section.rows[0].description, code: section.sectionId});
            this.section_effdts[section.sectionId] = [];
            section.rows.forEach( es => {
                this.section_effdts[section.sectionId].push({label: es.effectiveDateModel, code: es.effectiveDateString} );
            });
        },        
        goEdit: function(sectionId, effdt, classId){
            this.$router.push('/class/' + sectionId + '/' + effdt + '/' + classId);   
        },
        addClass: function(){     
            this.store.addClass().then((success)=>{
                if(success){
                    this.$refs.saveAlert.showAlert(
                    'success',
                    'La sección fue creada correctamente.',
                    'Éxito',
                    { 
                        iconSize: 35, 
                        iconType: 'solid', 
                        position: 'top right' } 
                    );
                    
                    this.goEdit(this.store.sectionId, this.store.effectiveDateString, this.store.classId);                       
                    
                } else {                    
                    this.$refs.saveAlert.showAlert(
                    'error',
                    'No fue posible crear la sección.',
                    'Alerta',
                    { 
                        iconSize: 35, 
                        iconType: 'solid', 
                        position: 'top right' } 
                    );
                }
            });                  
        },        
    }
}

</script>

<style scoped>

</style>