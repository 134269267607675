<template>
    <div class="section-index">        
        <LoadingSpinner  v-if="loading" />                           
        <div  v-if="!loading">
            <h1 class="bg-principal text-white text-left pl-5">Carga Manual</h1>  
            <br>
            <label>Archivo
            <input class="" type="file" @change="handleFileUpload( $event )"/>
        </label>
        <hr class="my-4">
        <button v-if="file" type="button" class="mx-4 text-white bg-principal border border-gray-400 hover:scale-105 font-medium rounded-lg text-xs px-5 py-2.5 text-center inline-flex items-center" v-on:click="submitFile()">
            Cargar
            <img :src="require('@/assets/icons/upload.svg')" class="ml-2 -mr-1 w-4 h-4"  />
        </button>               
        </div>        
    </div>
    <vue-basic-alert 
        :duration="300" 
        :closeIn="10000"
        ref="saveAlert" />    
</template>
<script>
import { useProductStore } from "@/stores/ProductStore"
import LoadingSpinner from '@/components/LoadingSpinner.vue'
// eslint-disable-next-line
import VueBasicAlert from 'vue-basic-alert'

export default {
    name: 'UploadManualView',
    components:{
        LoadingSpinner,
    },
    data(){
        return {            
            file: '',  
            error: false,
            error_msg: "",
            loading: true,
            store : useProductStore(),
            headers:[],
            rows: [],
            actions: [],
        }
    },
    mounted(){   
        this.store = useProductStore(); 
        this.loading = false;
    },
     methods: {
            handleFileUpload(event){
                this.file = event.target.files[0];
            },
            submitFile(){
                this.loading = true;
                this.store.addFromFile(this.file).then(()=>{
                    
                    this.file = '';
                    this.loading = false;
                    if(! this.store.message){
                        this.$refs.saveAlert.showAlert(
                            'success',
                            'El archivo fue cargado correctamente.',
                            'Éxito',
                            { 
                                iconSize: 35, 
                                iconType: 'solid', 
                                position: 'top right' } 
                            ); 
                    } else {
                        this.$refs.saveAlert.showAlert(
                        'error',
                        'No fue posible cargar el archivo.',
                        'Alerta',
                        { 
                            iconSize: 35, 
                            iconType: 'solid', 
                            position: 'top right' } 
                        );
                    }
                });
            },        
        }
    }

</script>