<template>
    <div class="section-index">        
        <LoadingSpinner  v-if="loading" />                           
        <div  v-if="!loading">
            <h1 class="bg-principal text-white text-left pl-5">Índice</h1>
            <DataTable :headers="headers" :rows="rows" :actions="actions"/>
            <action-bar :custom1_action="runIndex" :custom1_label="'Ejecutar Índice'" />               
        </div>
        <confirm-dialogue ref="InputDialogue">
            <input type="date" v-model="indexDate">
        </confirm-dialogue>
    </div>
</template>
<script>
import { useIndexStore } from "@/stores/IndexStore"
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import DataTable from '@/components/DataTable.vue'
import ActionBar from '@/components/ActionBar.vue'
import ConfirmDialogue  from "@/components/ConfirmDialogue";

export default {
    name: 'SectionView',
    components:{
        LoadingSpinner,
        DataTable,
        ActionBar,
        ConfirmDialogue
    },
    data(){
        return {
            indexes: [],   
            indexDate: new Date(),        
            error: false,
            error_msg: "",
            loading: true,
            store : useIndexStore(),
            headers:[],
            rows: [],
            actions: [],
        }
    },
    mounted(){   
        this.store = useIndexStore(); 
        this.headers = ['Fecha', 'Estado', 'Valor'];
        
        this.actions['excel'] = this.excelRowAction;  

        this.store.fetchIndexes().then(()=>{
            this.loading = false;
            this.indexes = this.store.getIndexes;
            this.indexes.forEach( el => this.pushRow(el) );
        });                 
    },
     methods: {
        excelRowAction: function(id){
            console.log(id);
            this.loading = true;
            var fileName = id.substring(0, 10);
            this.store.downloadExcel(id).then( ()=>{
                var fileURL = window.URL.createObjectURL(new Blob([this.store.getFileData]));
                var fileLink = document.createElement('a');
    
                fileLink.href = fileURL;
                fileLink.setAttribute('download', "ipc_" + fileName + '.xlsx');
                document.body.appendChild(fileLink);
   
                fileLink.click();
                this.loading = false;
                }               
            );

            //this.$router.push('/section/' + sectionId);   
        },          
        pushRow: function(obj){
            let element = obj;            
            let row = [];        
                   
            row['excel_id'] = element.indexDateModel + '/excel';                
            row['data'] = [element.indexDateModel, element.status, element.amount];
            this.rows.push(row);
        },
        runIndex: function(){
            this.$refs.InputDialogue.show({
            title: 'Ejecutar Índice',
            message: 'Ingresa la nueva fecha de Ejecución',
            okButton: 'Ejecutar',
            cancelButton: 'Cancelar',
            icon: require('@/assets/icons/index.svg'),
            }).then((result) => {
                if (result) {
                    this.loading = true;
                    //let runDate = this.indexDate.getDate() + '/'+ (this.indexDate.getMonth()+1)+'/'+this.indexDate.getFullYear();            
                    this.store.runIndex(this.indexDate).then(()=>{
                    this.$router.go();   
                    });
                } else {
                    //nothing
                }
            })
        }
    }
}
</script>