<template>
    <div class="section-index">        
        <LoadingSpinner  v-if="loading" />                           
        <div  v-if="!loading">
            <h1 class="bg-principal text-white text-left pl-5">Sección</h1>
            <DataTable :headers="headers" :rows="rows" :actions="actions"/>
            <action-bar :custom1_action="addSection" :custom1_label="'Crear Sección'" />               
        </div>
    </div>
</template>
<script>
import { useSectionStore } from "@/stores/SectionStore"
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import DataTable from '@/components/DataTable.vue'
import ActionBar from '@/components/ActionBar.vue'

export default {
    name: 'SectionView',
    components:{
        LoadingSpinner,
        DataTable,
        ActionBar
    },
    data(){
        return {
            sections: [],            
            error: false,
            error_msg: "",
            loading: true,
            store : useSectionStore(),
            headers:[],
            rows: [],
            actions: [],
        }
    },
    mounted(){   
        this.store = useSectionStore(); 
        this.headers = ['ID', 'Fecha Efectiva', 'Descripción', 'Estado', 'Coeficiente'];
        
        this.actions['edit'] = this.editRowAction;  
        this.actions['add'] = this.addEffdtRowAction;  
        this.actions['delete'] = this.deleteRowAction; 
        this.actions['detail'] = this.effdtRowAction; 

        this.store.fetchSections().then(()=>{
            this.loading = false;
            this.sections = this.store.getSections;
            this.sections.forEach( el => this.pushRow(el) );
        });                 
    },
     methods: {
        effdtRowAction: function(sectionId){
            this.$router.push('/section/' + sectionId);   
        },
        editRowAction: function(editId){
            this.$router.push('/section/' + editId);   
        },
        addEffdtRowAction: function(addId){
            this.loading = true;
            this.store.addEffdtSection(addId).then(()=>{
               this.$router.push('/section/' + addId);        
            });            
        },
        deleteRowAction: function(deleteId){
            this.loading = true;
            this.store.deleteSection(deleteId).then(()=>{
                this.$router.go();     
            });      
        },        
        pushRow: function(section){
            let element = section.rows.pop();            
            let row = [];        
                   
            let effectiveDate = element.effectiveDate.getDate() + '/'+ (element.effectiveDate.getMonth()+1)+'/'+element.effectiveDate.getFullYear();
            row['edit_id'] = section.sectionId + '/' + element.effectiveDateString;     
            row['add_id'] = section.sectionId;     
            row['delete_id'] = section.sectionId;
            row['detail_id'] = section.sectionId;
            row['data'] = [section.sectionId, effectiveDate, element.description, element.effectiveStatus , element.coefficient];
            this.rows.push(row);
        },
        addSection: function(){            
            this.$router.push('/sections/add');   
        }
    }
}
</script>