<template>
<div class="dashboard p-5">
    <LoadingSpinner  v-if="loading" /> 

    <div class="grid gap-4 grid-cols-1 md:grid-cols-3 lg:grid-cols-7" v-if="!loading">
        <div v-for="source in sources" :key="source.source">        
            <a class="block p-6 max-w-sm m-auto rounded-lg border border-gray-200 hover:border-gray-300 hover:opacity-75  shadow-md h-30 md:h-40" v-bind:class="['bg-'+source.source+'-primary', 'hover:bg-'+source.source+'-primary-100']">
            <img :src="require('@/assets/logos/' + source.source +'.svg')" class="m-auto h-20 w-20" :alt="source.source">            
            <p class="text-white text-sm" v-if="source.status=='Completed'">{{new Date(source.scrapDate).toLocaleDateString()}}</p>
            <p class="text-white text-sm" v-if="source.status=='Completed'">#{{source.count}}</p>
        </a>
        </div>
    </div>
    <DataTable :headers="headers" :rows="rows" :pagination="pagination"/>                           
</div>    
</template>

<script>
import { useSourceStore } from "@/stores/SourceStore"
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import DataTable from '@/components/DataTable.vue'

export default {
    name: 'DashboardView',
    components:{
        LoadingSpinner,
        DataTable
    },
    data(){
        return {
            sources: [],            
            error: false,
            error_msg: "",
            loading: true,
            store : useSourceStore(),
            sourceList : [],
            headers:[],
            rows: [],
            actions: [],
            pagination: {
                total: 0,
                current: 1,
                size: 0,
                refresh: this.refreshSourceList
            } 
        }
    },
    mounted(){  
        this.headers = ['Fecha', 'Origen', 'Estado'];

        this.store.fetchSources().then(()=>{
            this.loading = false;
            this.sources = this.store.getSources;
        });   
        
        this.store.fetchSourceList().then(()=>{            
            this.sourceList = this.store.getSourceList;
            this.sourceList.forEach( el => this.pushRow(el) );
            this.pagination.total = this.store.sourcelist_total_pages;   
            this.pagination.size = this.store.sourcelist_page_size;
            this.pagination.current = this.store.sourcelist_page;
        })
    },
    methods: {
        refreshSourceList(page){            
            this.store.sourcelist_page = page;    
            this.pagination.current = page;
            this.store.fetchSourceList().then(()=>{       
                this.rows = [];
                this.sourceList = this.store.getSourceList;
                this.sourceList.forEach( el => this.pushRow(el) );
                this.pagination.total = this.store.sourcelist_total_pages;   
                this.pagination.size = this.store.sourcelist_page_size;
                this.pagination.current = this.store.sourcelist_page;
            })
        },
        pushRow: function(obj){
            let element = obj;            
            let row = [];        
                   
           row['data'] = [element.scrapDate.toLocaleDateString('es-ar', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) , element.source, element.status];
            this.rows.push(row);
        },
        viewSource: function(source, sourceDate){
            /*this.store.setSource(source, sourceDate);
            this.$router.push('/products');   */
            console.log(source + " " + sourceDate);
        }
    }
}

</script>

<style scoped>

</style>