import { createRouter, createWebHistory } from 'vue-router'
import LoginView from '@/views/LoginView.vue'
import DashboardView from '@/views/DashboardView.vue'
import ProductsView from '@/views/ProductsView.vue'
import SectionIndexView from '@/views/section/SectionIndexView.vue'
import SectionAddView from  '@/views/section/SectionAddView.vue'
import SectionEditView from  '@/views/section/SectionEditView.vue'
import SectionEffdtView from '@/views/section/SectionEffdtView.vue'
import ClassIndexView from '@/views/class/ClassIndexView.vue'
import ClassAddView from '@/views/class/ClassAddView.vue'
import ClassEditView from '@/views/class/ClassEditView.vue'
import ClassEffdtView from '@/views/class/ClassEffdtView.vue'
import SubclassIndexView from '@/views/subclass/SubclassIndexView.vue'
import SubclassAddView from '@/views/subclass/SubclassAddView.vue'
import SubclassEditView from '@/views/subclass/SubclassEditView.vue'
import SubclassEffdtView from '@/views/subclass/SubclassEffdtView.vue'
import IndexView from '@/views/IndexView.vue'
import UploadManualView from '@/views/UploadManualView.vue'
import SubclassAddProductView from '@/views/subclass/SubclassAddProductView.vue'
import UnconfiguredProductsView from '@/views/UnconfiguredProductsView.vue'

import { useAuthStore } from '@/stores/AuthStore' 

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: DashboardView
  },
  {
    path: '/products',
    name: 'products',
    component: ProductsView
  },
  {
    path: '/unconfiguredproducts',
    name: 'unconfiguredproducts',
    component: UnconfiguredProductsView
  },
  /*{
    path: '/',
    name: 'home',
    component: DashboardView
  },*/
  {
    path: '',
    name: 'url',
    component: DashboardView
  },
  {
    path: '/sections',
    name: 'sections',
    component: SectionIndexView
  },
  {
    path: '/sections/add',
    name: 'add section',
    component: SectionAddView
  },
  {
    path: '/section/:id/:effectiveDate',
    name: 'edit section',
    component: SectionEditView
  },
  {
    path: '/section/:id',
    name: 'section effdts',
    component: SectionEffdtView
  },
  {
    path: '/classes',
    name: 'classes',
    component: ClassIndexView
  },  
  {
    path: '/classes/add',
    name: 'add class',
    component: ClassAddView
  },
  {
    path: '/class/:sectionId/:effectiveDate/:classId',
    name: 'edit class',
    component: ClassEditView
  },
  {
    path: '/class/:sectionId/:classId',
    name: 'class effdts',
    component: ClassEffdtView
  },
  {
    path: '/subclasses',
    name: 'subclasses',
    component: SubclassIndexView
  },  
  {
    path: '/subclasses/add',
    name: 'add subclass',
    component: SubclassAddView
  },
  {
    path: '/subclass/:sectionId/:effectiveDate/:classId/:subclassId',
    name: 'edit subclass',
    component: SubclassEditView
  },
  {
    path: '/subclass/:sectionId/:classId/:subclassId',
    name: 'subclass effdts',
    component: SubclassEffdtView
  },
  {
    path: '/subclass/:sectionId/:effectiveDate/:classId/:subclassId/addproduct',
    name: 'add product to subclass',
    component: SubclassAddProductView
  },
  {
    path: '/index',
    name: 'index',
    component: IndexView
  },
  {
    path: '/manual',
    name: 'manual',
    component: UploadManualView
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const auth = useAuthStore(); 

  if (authRequired && !auth.isAutenticated) {
      return '/login';
  }
});