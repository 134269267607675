<template>
    <div class="flex flex-col pb-12">                
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                <div class="overflow-hidden max-w-5xl">
                    <table class="table-fixed w-full">
                    <thead class="bg-white border-b">
                        <tr>                            
                        <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-center tracking-wider"  v-for="header in headers" :key="header">
                            {{header}}
                        </th>  
                        <th scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-center tracking-wider"  v-if="actions">
                            Acciones
                        </th>                                                
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100" v-for="row in rows" :key="row.id">                            
                            <td class="text-sm text-gray-900 font-light px-6 py-4 text-center break-all" v-for="data in row.data" :key="data">
                                {{data}}
                            </td>
                            <td scope="col" class="text-sm font-medium text-gray-900 px-6 py-4 text-center"  v-if="actions">
                                <div class="flex space-x-2 text-center m-auto justify-center">
                                    <a class="cursor-pointer" v-if="actions['edit']" v-on:click="actions['edit'](row.edit_id)">
                                        <img :src="require('@/assets/icons/edit.svg')" class="m-auto h-4 w-4 hover:scale-110" title="Editar">  
                                    </a>
                                    <a class="cursor-pointer" v-if="actions['add']" v-on:click="doAddEffdt(row.add_id)">
                                        <img :src="require('@/assets/icons/add.svg')" class="m-auto h-4 w-4 hover:scale-110" title="Agregar Fecha Efectiva">  
                                    </a>
                                    <a class="cursor-pointer" v-if="actions['select']" v-on:click="actions['select'](row.select_id)">
                                        <img :src="require('@/assets/icons/add.svg')" class="m-auto h-4 w-4 hover:scale-110" title="Seleccionar">  
                                    </a>
                                    <a class="cursor-pointer" v-if="actions['detail']" v-on:click="actions['detail'](row.detail_id)">
                                        <img :src="require('@/assets/icons/detail.svg')" class="m-auto h-4 w-4 hover:scale-110" title="Ver Detalle">  
                                    </a>
                                    <a class="cursor-pointer" v-if="actions['delete']"   v-on:click="doDelete(row.delete_id)" >
                                        <img :src="require('@/assets/icons/delete.svg')" class="m-auto h-4 w-4 hover:scale-110" title="Borrar">  
                                    </a>
                                    <a class="cursor-pointer" v-if="actions['excel']"   v-on:click="actions['excel'](row.excel_id)" >
                                        <img :src="require('@/assets/icons/excel.svg')" class="m-auto h-4 w-4 hover:scale-110" title="Excel">  
                                    </a>
                                </div>
                                <!--<a href="#" v-on:click="goEdit(section.id, section.effectiveDate)">
                                    <img :src="require('@/assets/icons/edit.svg')" class="m-auto h-3 w-3">  
                                </a>-->
                            </td>                            
                        </tr>
                    </tbody>
                    </table>
                    <div v-if="pagination">
                        <nav class="relative inline-flex rounded-md mt-2" aria-label="Pagination">
                         <label class="mx-2 my-auto">Pág</label>
                         <select class="w-full  text-sm border-gray-200 rounded-lg focus:border-gray-200 focus:ring-0" v-model="current" :reduce="option => option.code" @change="changePage()">
                            <option v-for="index in pagination.total" :key="index" :value="index"> {{index}} </option>                                   
                         </select>                  
                        <span class="px-2 my-auto "> de </span> 
                        <span class="px-2 my-auto "> {{pagination.total}} </span>                                                 
                    </nav>
                    </div>
                </div>                
            </div>
        </div>
        <confirm-dialogue ref="ConfirmDialogue"></confirm-dialogue>  
        <confirm-dialogue ref="InputDialogue">
            <input type="date" v-model="effdt">
        </confirm-dialogue>
    </div>   
         
</template>
<script>

import ConfirmDialogue  from "@/components/ConfirmDialogue";

export default {
    name: 'DataTable',
    components: {    
    ConfirmDialogue    
  },
   data(){
    return {
        effdt : new Date(),
        current : "1"
    }
   },
    props: {
        headers: Array,
        rows: Array,
        actions: Array,
        pagination: Object,          
    },
    created(){    
        if(this.pagination){
            this.current =  this.pagination.current;  
        } 
        
    },
     methods: {     
        async changePage(){
            await this.pagination.refresh(this.current);
        },
        async doDelete(delete_id) {
            this.$refs.ConfirmDialogue.show({
            title: 'Atención',
            message: '¿Quieres borrar la fila?',
            okButton: 'Borrar',
            cancelButton: 'Cancelar',
            icon: require('@/assets/icons/alert.svg'),
            }).then((result) => {
                if (result) {
                    this.actions['delete'](delete_id);
                } else {
                    //nothing
                }
            })
        },
        async doAddEffdt(id) {
            this.$refs.InputDialogue.show({
            title: 'Crear nueva fecha efectiva',
            message: 'Ingresa la nueva fecha',
            okButton: 'Crear',
            cancelButton: 'Cancelar',
            icon: require('@/assets/icons/alert.svg'),
            }).then((result) => {
                if (result) {
                    this.actions['add'](id + '/' + this.effdt);
                } else {
                    //nothing
                }
            })
        },
    
  },
}

</script>

<style scoped>

</style>
