import {defineStore} from 'pinia'
import { markRaw } from 'vue'
import router from '@/helpers/router'

export const useNavStore  = defineStore(
    {   
        id: "NavStore",        
        state: ()=>({           
                items : [
                    {
                        path: '/dashboard',
                        id: 'dashboard',
                        description: 'Panel Principal',
                        icon: 'home.svg',
                        subitems: []
                    },  
                    {
                        path: '#',
                        id: 'configuracion',
                        description: 'Configuración',
                        icon: 'settings.svg',
                        subitems: [
                            {
                                path: '/sections',
                                id: 'sections',
                                description: 'Secciones',
                            },
                            {
                            path: '/classes',
                            id: 'classes',
                            description: 'Clases',
                            },
                            {
                            path: '/subclasses',
                            id: 'subclasses',
                            description: 'Subclases',
                            },
                            {
                            path: '/unconfiguredproducts',
                            id: 'unconfiguredproducts',
                            description: 'Productos',
                            }
                        ]
                    },  
                    {
                        path: '/index',
                        id: 'indice',
                        description: 'Índice',
                        icon: 'index.svg',
                        subitems: []
                    },                      
                    {
                        path: '/manual',
                        id: 'manual',
                        description: 'Carga Manual',
                        icon: 'upload.svg',
                        subitems: []
                    },                                    
                    {
                        path: '/login',
                        id: 'logout',
                        description: 'Logout',
                        icon: 'logout.svg',
                        subitems: []
                    }
                ],
            breadcum : '' ,
            router : markRaw(router),                              
        }), 
        getters:{
            getNavItems(){                
                return this.items;
            }
        },
        actions:{ 
            go(item){                                
                if(item.subitems){
                    if(item.subitems.length > 0){
                        return;
                    } else {
                        this.breadcum = item.description;
                        this.router.push(item.path);
                    }                
                } else {
                    this.breadcum = item.description;
                    this.router.push(item.path);
                }                
            }          
        }                      
    }    
)